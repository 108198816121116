(function(){
	$('#btnLogin').click(function (e) {
		e.preventDefault();
		$('#loginForm').submit();
	});

	$('input').keypress(function (e) {
		if (e.which == 13) {
			$('#loginForm').submit();
			return false;
		}
	});
})();